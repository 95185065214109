import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import Loadable from "react-loadable";
import { navigate } from "gatsby";
import { useAppDispatch } from "../../../../../store";
import {
  isIndividualAuctionLoading,
  selectHasSuccessfullyDeleted,
  selectIndividualAuction,
  selectIndividualAuctionId,
} from "../../../../../store/individualAuction/selectors";
import {
  deleteIndividualAuction,
  getIndividualAuction,
  StoreAuctionPartial,
} from "../../../../../store/individualAuction/actions";
import {
  editAuction,
  setPath,
} from "../../../../../store/auctionModal/reducer";
import {
  selectRequestingUserInfo,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../../../../store/user/selectors";
import { User } from "../../../../../types/User";
import styles from "./AuctionDetails.module.scss";
import { getAuctionState, sendToast } from "../../../../../utils/helpers";
import { AuctionStateTypes } from "../../../../../enums/AuctionStateTypes";
import RoundedButton from "../../../../Buttons/RoundedButton";
import { Auction } from "../../../../../types/Auction";
import { fetchBids } from "../../../../../store/bidActivity/actions";
import {
  failedUpdateAuction,
  successfullyDraftedAuction,
  successfullyUpdatedAuction,
} from "../../../../../store/auctions/selectors";
import { AuctionPartial } from "../../../../../store/auctionModal/actions";
import { patchAuction } from "../../../../../store/auctions/actions";
import { toggleIndividualAuctionModal } from "../../../../../store/individualAuction/reducer";
import { getStoreById } from "../../../../../store/stores/actions";
import { getUserInfo } from "../../../../../store/user/actions";
import FullScreenLoading from "../../../../Loading/FullScreenLoading";
import LoadingSmall from "../../../../Loading/LoadingSmall";
import { PathTypes } from "../NewAuctionCreation/Types/PathTypes";
import DraftModal from "../AuctionCreation/internal/DraftModal/DraftModal";

const LoadableAuctionNav = Loadable({
  loader: () => import("./internal/AuctionDetailsNav"),
  loading: LoadingSmall,
});

const LoadableAuctionDetailsImage = Loadable({
  loader: () => import("./internal/AuctionDetailsImage"),
  loading: LoadingSmall,
});

const LoadableAuctionDetailsTable = Loadable({
  loader: () => import("./internal/AuctionDetailsTable"),
  loading: LoadingSmall,
});

const AuctionDetails = () => {
  const dispatch = useAppDispatch();

  const auction = useSelector((state) =>
    selectIndividualAuction(state)
  ) as Auction;
  const auctionId = useSelector((state) =>
    selectIndividualAuctionId(state)
  ) as number;
  const user = useSelector((state) => selectUserInfo(state)) as User;
  const hasFailedUpdate = useSelector((state) =>
    failedUpdateAuction(state)
  ) as boolean;
  const loading = useSelector(
    (state) => isIndividualAuctionLoading(state) || selectUserInfoLoading(state)
  ) as boolean;
  const [showDraftModal, setShowDraftModal] = useState(false);
  const hasDrafted = useSelector((state) =>
    successfullyDraftedAuction(state)
  ) as boolean;
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [complete, setComplete] = useState(false);
  const hasUpdated = useSelector((state) =>
    successfullyUpdatedAuction(state)
  ) as boolean;
  const successfullyDeleted = useSelector((state) =>
    selectHasSuccessfullyDeleted(state)
  ) as boolean;
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  useEffect(() => {
    if (hasDrafted) {
      // handle here
      setComplete(true);
    }
  }, [hasDrafted]);

  useEffect(() => {
    if (!user && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
    if (auctionId && user) {
      const storeAuctionPartial = {
        storeId: user.data.last_store_id,
        auctionId,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(storeAuctionPartial));
      dispatch(fetchBids(storeAuctionPartial));
    }
  }, []);

  useEffect(() => {
    if (successfullyDeleted) {
      // handle here
      setComplete(true);
      dispatch(toggleIndividualAuctionModal(false));
      navigate("/dashboard/");
    }
  }, [successfullyDeleted]);

  useEffect(() => {
    if (hasFailedUpdate) {
      setComplete(true);
    }
  }, [hasFailedUpdate]);

  useEffect(() => {
    if (hasUpdated) {
      setComplete(true);
      // Save the draft if its a draft
    }
  }, [hasUpdated]);

  useEffect(() => {
    if (auctionId && user) {
      const storeAuctionPartial = {
        storeId: user.data.last_store_id,
        auctionId,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(storeAuctionPartial));
      dispatch(fetchBids(storeAuctionPartial));
    }
  }, [auctionId, user]);

  useEffect(() => {
    if (user) {
      dispatch(getStoreById(user.data.last_store_id));
    }
  }, [user]);

  const edit = () => {
    if (auction) {
      dispatch(editAuction(auction));
      dispatch(setPath(PathTypes.EDIT_AUCTION));
      navigate(`/dashboard/auctions/${auction.id}/add-title/`);
    }
  };

  const cancelAuction = () => {
    setShowDraftModal(true);
  };

  const handleSaveDraft = () => {
    if (!auction.name) {
      sendToast(
        "Please enter a title for your auction before you save as a draft.",
        { type: "warning" }
      );
      return;
    }
    setLoadingAnimation(true);
    const newAuct = JSON.parse(JSON.stringify(auction));
    newAuct.progress = "draft";
    const auctionPartial = {
      auction: newAuct,
      userInfo: user,
    } as AuctionPartial;
    Object.preventExtensions(newAuct);
    dispatch(patchAuction(auctionPartial));
  };

  const handleDeletion = () => {
    if (!auction) {
      return;
    }
    setLoadingAnimation(true);
    const auctionPartial = {
      auctionId: auction.id,
      storeId: user.data.last_store_id,
    } as StoreAuctionPartial;

    dispatch(deleteIndividualAuction(auctionPartial));
  };

  const auctionState = getAuctionState(auction);
  const allowsEditCancel =
    auctionState !== AuctionStateTypes.publish &&
    auctionState !== AuctionStateTypes.finish &&
    auctionState !== AuctionStateTypes.confirm &&
    !loading;
  return (
    <>
      <div className={styles.auctionDetailsContainer}>
        {!loading && <LoadableAuctionNav />}
        {!loading && <LoadableAuctionDetailsImage />}
        {!loading && <LoadableAuctionDetailsTable />}
        {auctionState === AuctionStateTypes.finish && (
          <div className={cn([styles.buttonContainer, styles.deleteButtons])}>
            <RoundedButton onClick={() => cancelAuction()}>
              DELETE
            </RoundedButton>
          </div>
        )}
        {allowsEditCancel && (
          <div className={styles.buttonContainer}>
            <RoundedButton onClick={() => cancelAuction()}>
              CANCEL AUCTION
            </RoundedButton>
            {auctionState !== AuctionStateTypes.error && (
              <RoundedButton onClick={() => edit()}>EDIT</RoundedButton>
            )}
          </div>
        )}
        {loading && <FullScreenLoading />}
      </div>
      {showDraftModal && (
        <DraftModal
          handleDeletion={handleDeletion}
          handleSaveDraft={handleSaveDraft}
          onBack={() => setShowDraftModal(false)}
          onClose={() => setShowDraftModal(false)}
          auctionState={auctionState}
          loadingAnimation={loadingAnimation}
          hasFailedUpdate={hasFailedUpdate}
          complete={complete}
        />
      )}
    </>
  );
};

export default AuctionDetails;
