import React from "react";
import cn from "classnames";
import { AuctionStateTypes } from "../../../../../../../enums/AuctionStateTypes";
import styles from "./DraftModal.module.scss";
import Failed from "./Animations/Failed";
import Success from "./Animations/Success";

const DraftModal = ({
  handleDeletion,
  handleSaveDraft,
  onBack,
  onClose,
  auctionState,
  loadingAnimation,
  hasFailedUpdate,
  complete,
}) => {
  const FailedText = hasFailedUpdate ? "Update failed" : "Updated!";
  const allowsCancel =
    auctionState !== AuctionStateTypes.finish &&
    auctionState !== AuctionStateTypes.confirm;
  const allowsDraft =
    auctionState !== AuctionStateTypes.finish &&
    auctionState !== AuctionStateTypes.confirm &&
    auctionState !== AuctionStateTypes.error;
  return (
    <div className={styles.draftModalContainer}>
      <div className={styles.draftPopup}>
        <div className={styles.draftPopupContainer}>
          {!loadingAnimation && (
            <>
              <p>
                Are you sure you want to{" "}
                {auctionState === AuctionStateTypes.finish
                  ? "Delete"
                  : "Cancel"}{" "}
                the Auction? Changes will not be saved if cancelled.
              </p>
              {allowsCancel && (
                <button
                  type="button"
                  className="text-bidddyPink"
                  onClick={() => handleDeletion()}
                >
                  Yes, Cancel Auction
                </button>
              )}
              {auctionState === AuctionStateTypes.finish && (
                <button
                  type="button"
                  className="text-bidddyPink"
                  onClick={() => handleDeletion()}
                >
                  Yes Delete Auction
                </button>
              )}
              {allowsDraft && (
                <button
                  type="button"
                  className="text-bidddyAltGray"
                  onClick={() => handleSaveDraft()}
                >
                  Save as Draft
                </button>
              )}
              <button
                type="button"
                className="text-bidddyAltGray"
                onClick={() => onBack()}
              >
                Back
              </button>
            </>
          )}
          {loadingAnimation && (
            <>
              <div
                className={cn([
                  {
                    [styles.circleLoader]: loadingAnimation && !hasFailedUpdate,
                    [styles.loadComplete]: complete && !hasFailedUpdate,
                  },
                  styles.loaderContainer,
                ])}
              >
                {complete && !hasFailedUpdate && <Success />}
                {complete && hasFailedUpdate && <Failed />}
              </div>
              <p className="text-center">
                {complete ? FailedText : "Updating..."}
              </p>
            </>
          )}
          {complete && (
            <button
              type="button"
              className={styles.close}
              onClick={() => onClose()}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DraftModal;
