import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../components/Auth/auth";
import DashboardLayout from "../../../components/DashboardLayout";
import { selectIndividualAuctionId } from "../../../store/individualAuction/selectors";
import AuctionDetails from "../../../components/PageComponents/Dashboard/Auctions/AuctionDetails";
import { useAppDispatch } from "../../../store";
import {
  setAuctionId,
  toggleIndividualAuctionModal,
} from "../../../store/individualAuction/reducer";
import { selectUserInfo } from "../../../store/user/selectors";
import { User } from "../../../types/User";
import { sendToast } from "../../../utils/helpers";

export default function EditAuctionsPage({ id }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id && !Number.isNaN(parseInt(id, 10))) {
      dispatch(setAuctionId(id));
      dispatch(toggleIndividualAuctionModal(true));
    } else {
      sendToast(
        "There was an error getting the id of the auction. Please try again",
        { type: "error" }
      );
    }
  });

  const auctionId = useSelector((state) =>
    selectIndividualAuctionId(state)
  ) as number;
  const user = useSelector((state) => selectUserInfo(state)) as User;

  return (
    <AuthProvider>
      <DashboardLayout
        style={{ paddingTop: 0 }}
        hideNav
        title="Bidddy - Edit Auction"
        hideFooter
      >
        {auctionId && user && <AuctionDetails />}
      </DashboardLayout>
    </AuthProvider>
  );
}
